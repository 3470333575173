<template>
  <templateComponent>
    <div class="nt">
      <div class="nt-title">
        <img src="@/assets/images/novice-teaching.png" alt="">
        <span>{{ $t("新手教学") }}</span>
      </div>
      <myTabs :list="list.map(item => item.name)" v-if="list.length && !isMobileFlag" @change="i => active = i">
      </myTabs>
      <el-select v-model="active" class="nt-select" v-else-if="list.length && isMobileFlag">
        <el-option v-for="item, index in list" :key="item.id" :label="item.name" :value="index"></el-option>
      </el-select>
      <div class="nt-item" v-for="item, index in list[active]?.teach_list" :key="index">
        <div class="nt-item-title">{{ item.news_title }}</div>
        <div class="nt-item-content html" v-html="item.news_content"></div>
      </div>
    </div>
  </templateComponent>
</template>

<script>
import myTabs from "@/components/myTabs.vue";
import templateComponent from '@/components/template.vue';
import { news_teach_list } from '@/api/news'
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      list: [],
      active: 0
    }
  },
  components: { templateComponent, myTabs },
  methods: {
    async getList() {
      const { data: res } = await news_teach_list({ page: 1, limit: 100 })
      if (res.code === 1) {
        this.list = res.data
      }
    }
  },
  async mounted() {
    const loadingCtx = Loading.service();
    await this.getList()
    loadingCtx.close()
  }
}
</script>

<style scoped lang="less" src="@/assets/css/noviceTeaching.less"></style>